import React from "react";
// import { IconXiajiantou } from "../../components/IconXiajiantou";
// import { Icon28Fanhui } from "../../icons/Icon28Fanhui";
import "./style.css";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useEffect } from 'react';
import { useInfoMutation, useUpdateMutation, useTimesMutation } from '../../store/pageApi2';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeXuan } from "../../store/pageSlice";
import Sign from '../qianming'

const Information = () => {
  
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [gccg12, setgccg12] = useState(t("f_meet_join"))
  const [gccg12id, setgccg12id] = useState(1)
  const [gccg12gou, setgccg12gou] = useState(-1)
  const [zzwc, setzzwc] = useState(-1)
  const [bjdl, setbjdl] = useState(-1)
  const [dxwy, setdxwy] = useState(-1)
  const [gccg13, setgccg13] = useState(t("f_meet_join"))
  const [yiyue, setyiyue] = useState(false)
  const [gccg13id, setgccg13id] = useState(1)
  const [gccg13gou, setgccg13gou] = useState(-1)
  const [hei, sethei] = useState(false)
  const [hei2, sethei2] = useState(false)
  const [done, setdone] = useState(false)
  const [queren, setqueren] = useState(false)
  const [queren2, setqueren2] = useState(false)
  const [queren3, setqueren3] = useState(false)
  const [chayue, setchayue] = useState(false)
  const nav = useNavigate()
  const [qian, setqian] = useState(false)
  const [ming, setming] = useState(false)
  const [kedian, setkedian] = useState(false)


  const [info, { data: infoData }] = useInfoMutation()
  const [times, { data: timesData, isSuccess }] = useTimesMutation()
  const [update] = useUpdateMutation()

  const tijiao = () => {

    if (zzwc < 0 || bjdl < 0 || dxwy < 0) {


      setqueren(true)
      setTimeout(() => {
        setqueren(false)
      }, 1500)
      return

    }
    if (yiyue == false) {


      setqueren2(true)
      setTimeout(() => {
        setqueren2(false)
      }, 1500)
      return

    }
    update({

      "lan": t("is_en") == 1 ? "en" : "zh",
      "token": localStorage.getItem('li_token'),

      "zzwc": zzwc,
      "bjdl": bjdl,
      "dxwy": dxwy,

      "user_id": localStorage.getItem('li_user_id')
    }).then(res => {
      console.log(res)
      if (res.data.rs === true) {
        setdone(true)
        dispatch(changeXuan(3))
        nav('/home/application', { replace: true })

      } else {

      }

    });
  }

  const yiyueHandle = (e) => {


    console.log(kedian + "-----可点")
    if (kedian) {
      setyiyue(e.target.checked)
    } else {

      setchayue(true)
      setTimeout(() => {
        setchayue(false)
      }, 1500)

    }
  }

  const yiyueHandle2 = () => {
    setqueren3(true);
    const div = document.documentElement.querySelector('.div');
    if (t("is_en") == '1') {
      div.style.height = '3750px'
    } else {
      div.style.height = '3300px'
    };
    document.documentElement.scrollTop = 0
  }

  const querenHandle = () => {
    setqueren3(false);
    setkedian(true);
    const div = document.documentElement.querySelector('.div');
    div.style.height = '3300px';
    setkedian(true)
  }
  const selectTime = (date) => {

    if (date === 12) {
      times({
        date: 12
      }).then(res => {
        console.log(res)
        if (res.data) {
          sethei(true)
        } else {

        }

      });
    } else {

      times({
        date: 13
      }).then(res => {
        console.log(res)
        if (res.data) {
          sethei2(true)
        } else {

        }

      });
    }

  }

  useEffect(() => {
    const div = document.documentElement.querySelector('.div');
    div.style.height=0
    setTimeout(() => {
      div.style.height='3300px'
    }, 1)
    dispatch(changeXuan(2))
    info({
      token: localStorage.getItem('li_token')
    }).then(res => {
      console.log(res)

      if (res.data.rs === 1) {

        setdone(true)
        let g12 = res.data.data.gccg12
        let g13 = res.data.data.gccg13
        if (g12 === null) {

          setgccg12gou(false)
        } else {
          setgccg12(g12.name)
          setgccg12gou(true)
          setgccg12id(g12.id)
        }
        if (g13 === null) {

          setgccg13gou(false)
        } else {
          setgccg13(g13.name)
          setgccg13gou(true)
          setgccg13id(g13.id)
        }
        setzzwc(res.data.data.zzwc)
        setbjdl(res.data.data.bjdl)
        setdxwy(res.data.data.dxwy)


      } else if (res.data.rs === 0) {
        localStorage.removeItem('li_token')
        localStorage.removeItem('li_user_id')
        nav('/login', { replace: true })
      } else {

      }

      if (res.data.internal == 1) {
        setdone(true)
      }

    });
  }, [])




  const timeItem = () => {
    return (
      <>
        <div style={{
          width: '2000px',
          height: '2000px',
          position: 'fixed',
          top: '0px',
          backgroundColor: "#000",
          opacity: 0.4,
          left: 0,
          zIndex: 2
        }} onClick={() => { sethei(false) }}></div>
        <div className="information-view-3">
          <div className="information-overlap-10" >
            {
              timesData[0].over === 1 ? <div className="information-text-wrapper-19 text-wrapper-kong">{timesData[0].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-19 "
                  onClick={() => { setgccg12id(timesData[0].id); setgccg12(timesData[0].name); sethei(false); setgccg12gou(true); setgccg13gou(false); setgccg13(t("f_meet_join")) }}
                >{timesData[0].name}</div>
            }
            {
              timesData[1].over === 1 ? <div className="information-text-wrapper-20 text-wrapper-kong">{timesData[1].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-20"
                  onClick={() => { setgccg12id(timesData[1].id); setgccg12(timesData[1].name); sethei(false); setgccg12gou(true); setgccg13gou(false); setgccg13(t("f_meet_join")) }}
                >{timesData[1].name}</div>
            }
            {
              timesData[2].over === 1 ? <div className="information-text-wrapper-21 text-wrapper-kong">{timesData[2].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-21"
                  onClick={() => { setgccg12id(timesData[2].id); setgccg12(timesData[2].name); sethei(false); setgccg12gou(true); setgccg13gou(false); setgccg13(t("f_meet_join")) }}
                >{timesData[2].name}</div>
            }
            {
              timesData[3].over === 1 ? <div className="information-text-wrapper-22 text-wrapper-kong">{timesData[3].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-22"
                  onClick={() => { setgccg12id(timesData[3].id); setgccg12(timesData[3].name); sethei(false); setgccg12gou(true); setgccg13gou(false); setgccg13(t("f_meet_join")) }}
                >{timesData[3].name}</div>
            }
            {/* <Icon20Danxuan className="icon-danxuan" /> */}



          </div>
        </div>
      </>
    )
  }
  const timeItem2 = () => {
    return (
      <>


        <div style={{
          width: '2000px',
          height: '2000px',
          position: 'fixed',
          top: '0px',
          backgroundColor: "#000",
          opacity: 0.4,
          left: 0,
          zIndex: 2
        }} onClick={() => { sethei2(false) }}></div>
        <div className="information-view-3">
          <div className="information-overlap-10" >
            {
              timesData[0].over === 1 ? <div className="information-text-wrapper-19 text-wrapper-kong">{timesData[0].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-19 "
                  onClick={() => { setgccg13id(timesData[0].id); setgccg13(timesData[0].name); sethei2(false); setgccg13gou(true); setgccg12gou(false); setgccg12(t("f_meet_join")) }}
                >{timesData[0].name}</div>
            }
            {
              timesData[1].over === 1 ? <div className="information-text-wrapper-20 text-wrapper-kong">{timesData[1].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-20"
                  onClick={() => { setgccg13id(timesData[1].id); setgccg13(timesData[1].name); sethei2(false); setgccg13gou(true); setgccg12gou(false); setgccg12(t("f_meet_join")) }}
                >{timesData[1].name}</div>
            }
            {
              timesData[2].over === 1 ? <div className="information-text-wrapper-21 text-wrapper-kong">{timesData[2].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-21"
                  onClick={() => { setgccg13id(timesData[2].id); setgccg13(timesData[2].name); sethei2(false); setgccg13gou(true); setgccg12gou(false); setgccg12(t("f_meet_join")) }}
                >{timesData[2].name}</div>
            }
            {
              timesData[3].over === 1 ? <div className="information-text-wrapper-22 text-wrapper-kong">{timesData[3].name}  {t("f_meet_fill")}</div> :
                <div className="information-text-wrapper-22"
                  onClick={() => { setgccg13id(timesData[3].id); setgccg13(timesData[3].name); sethei2(false); setgccg13gou(true); setgccg12gou(false); setgccg12(t("f_meet_join")) }}
                >{timesData[3].name}</div>
            }

          </div>
        </div>
      </>
    )
  }
  return (
    <div className="information">
      <div className="div" >
        {
          queren ?
            <div className="frame" >
              <div className="text-wrapper-frame">
                {t("v_confirm_title1")}
                <br />
                {t("v_confirm_title2")}
              </div>
            </div> : null
        }
        {
          queren2 ?
            <div className="frame2" >
              <div className="text-wrapper-frame">

                {t("请同意")}

              </div>
            </div> : null
        }
        {
          chayue ?
            <div className="frame2" >
              <div className="text-wrapper-frame">

                {t("请同意")}

              </div>
            </div> : null
        }
        {
          queren3 ?
            <div className="baomikuang" >
              <div className="baomi">


              </div>
              <div className="baomi-content">
                <img src={t("is_en") == '1' ? "/img/xieyi-8-30-2-en.png" : "/img/xieyi-8-29-zh.png"} alt="" className="xieyi" />
                {
                  ming ? <div className="basetu">
                    <img src={`${ming}`} alt="" />
                  </div> : null
                }

                {/* <div className="qianming" onClick={()=>{
                  setqian(true)
                }}> */}
                {/* <div className="qianming-btn">
                点击签名
                </div> */}

                {/* </div> */}

                <div className="qingqueren">
                  <img src={t("is_en") == '1' ? "/img/tongyi_en.png" : "/img/tongyi.png"} alt="" className="qingqueren-btn" onClick={querenHandle} style={t("is_en") == '1' ? { marginTop: "75px" } : {}}/>
                </div>

              </div>

            </div> : null
        }

        {
          hei ? timeItem() : null
        }
        {
          hei2 ? timeItem2() : null
        }
        {
          qian ? <Sign close={(e) => { setqian(false); setming(e); }} /> : null
        }
        <img src={t("is_en") == 1 ? "/img/logo-en.png" : "/img/login/logo.png"} alt="" className="logo" />


        <div className="group-18">

          <img
            className="mask-group"
            alt="Mask group"
            src="/img/2024bg.jpg"
          />
          <div className="text-wrapper-16 zhong" style={t("is_en") == '1' ? { fontSize: "34px" } : {}}>{t("f_title")}</div>
          <div className="group-19">
            <div className="text-wrapper-17">{t("f_address")}</div>
            <div className="overlap-group-wrapper-4">
              <div className="overlap-group-5">
                <div className="subtract-wrapper">
                  <img
                    className="subtract"
                    alt="Subtract"
                    src="/img/img2/dot.svg"
                  />
                </div>
                <div className="ellipse" />
              </div>
            </div>
          </div>
          <div className="text-wrapper-18" style={t("is_en") == '1' ? { fontSize: "23px" } : {}}>
            {t("f_tips_title")}
            <br />
            {t("f_tips_desc")}
          </div>
          <img
            className="image"
            alt="Image"
            src="/img/img2/t2.svg"
            style={t("is_en") == '1' ? { top: "1052px" } : {}}
          />
          <img
            className="img"
            alt="Image"
            src="/img/img2/t2.svg"
          />
          <div className="group-20">
            <div className="text-wrapper-17">      {t("f_hotel")}</div>
            <div className="overlap-group-wrapper-4">
              <div className="overlap-group-6">
                <img
                  className="vector"
                  alt="Vector"
                  src="/img/img2/flag.svg"
                />
                <img
                  className="vector-2"
                  alt="Vector"
                  src="/img/img2/line.svg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="group-2">
          <div className="text-wrapper-2"  style={t("is_en") == '1' ? { top: "-120px" } : {}}>{t("f_meet_title")}</div>
          <div className="text-wrapper-3">{t("f_meet_date12")}</div>

          <div className="group-6">
            <div className="overlap-wrapper">
              <div className="overlap">
                <div className="group-3">
                  <div className="overlap-group-wrapper-3">
                    <button
                      className={bjdl === 1 ? "overlap-group-2 xuanzhong" : "overlap-group-2 weixuan"}
                      onClick={() => { setbjdl(1) }} disabled={done}
                    >
                      <div className="text-wrapper-9">{t("f_meet_join")}</div>
                    </button>
                  </div>
                  <div className="overlap-group-wrapper-2">
                    <button
                      className={bjdl === 0 ? "overlap-group-2 xuanzhong" : "overlap-group-2 weixuan"}
                      onClick={() => { setbjdl(0) }} disabled={done}
                    >
                      <div className="text-wrapper-10">{t("f_meet_not_join")}</div>
                    </button>
                  </div>
                </div>
                <div className="text-wrapper-8">{t("f_meet_date12_x1")}</div>
                <div className="group-7">
                  <div className="text-wrapper-11">{t("f_meet_date12_x1_address")}</div>
                  <div className="text-wrapper-6">{t("f_meet_date12_x1_title")}</div>
                </div>

              </div>
            </div>
          </div>
          <div className="group-8">
            <div className="overlap-wrapper">
              <div className="overlap">
                <div className="group-3">
                  <div className="overlap-group-wrapper-3">
                    <button
                      className={dxwy === 1 ? "overlap-group-2 xuanzhong" : "overlap-group-2 weixuan"}
                      onClick={() => { setdxwy(1) }} disabled={done}
                    >
                      <div className="text-wrapper-9">{t("f_meet_join")}</div>
                    </button>
                  </div>
                  <div className="overlap-group-wrapper-2">
                    <button
                      className={dxwy === 0 ? "overlap-group-2 xuanzhong" : "overlap-group-2 weixuan"}
                      onClick={() => { setdxwy(0) }} disabled={done}
                    >
                      <div className="text-wrapper-10">{t("f_meet_not_join")}</div>
                    </button>
                  </div>
                </div>
                <div className="text-wrapper-8">{t("f_meet_date12_x3")}</div>
                <div className="group-7">
                  <div className="text-wrapper-11">{t("f_meet_date12_x3_address")}</div>
                  <div className="text-wrapper-6">{t("f_meet_date12_x3_title")}</div>
                </div>

              </div>
            </div>
          </div>
          <div className="group-10">
            <div className="group-11">
              <div className="overlap">
                <div className="group-3">
                  <div className="overlap-group-wrapper-3">
                    <button
                      className={zzwc === 1 ? "overlap-group-2 xuanzhong" : "overlap-group-2 weixuan"}
                      onClick={() => { setzzwc(1) }} disabled={done}
                    >
                      <div className="text-wrapper-9">{t("f_meet_join")}</div>
                    </button>
                  </div>
                  <div className="overlap-group-wrapper-2">
                    <button
                      className={zzwc === 0 ? "overlap-group-2 xuanzhong" : "overlap-group-2 weixuan"}
                      onClick={() => { setzzwc(0) }} disabled={done}
                    >
                      <div className="text-wrapper-10">{t("f_meet_not_join")}</div>
                    </button>
                  </div>
                </div>
                <div className="text-wrapper-8"> {t("f_meet_date12_z1")}</div>
                <div className="text-wrapper-13"> {t("f_meet_date12_z1_address")}</div>
                <div className="text-wrapper-14">{t("f_meet_date12_z1_title")}</div>

              </div>
            </div>
          </div>
          <div className="group-12">
            <div className="group-13">
              <div className="overlap-group-4">
                <div className="text-wrapper-8">{t("f_meet_date12_z2")}</div>
                <div className="text-wrapper-13">{t("f_meet_date12_z2_address")}</div>
                <div className="text-wrapper-14">{t("f_meet_date12_z2_title")}</div>
              </div>
            </div>
          </div>
          <div className="group-14">
            <div className="group-13">
              <div className="overlap-group-4">
                <div className="text-wrapper-8">{t("f_meet_date12_x2")}</div>
                <div className="text-wrapper-13">{t("f_meet_date12_x2_address")}</div>
                <div className="text-wrapper-14">{t("f_meet_date12_x2_title")}</div>
              </div>
            </div>
          </div>
        </div>



        {/*    <div className="text-wrapper-20">（报名截止日期：8月31日）</div>
       */} <div className="view-3" >
          {
            done ? null : <div>
              <div  >






                {/* <div style={t("is_en") == '1' ? { margin: '0 10px', width: '80%' } : {}} >
                  <label htmlFor="gou"> {t("同意")}</label>{t("is_en") == '1' ? <br></br> : null}
                  <span style={{ textDecoration: 'underline' }} onClick={yiyueHandle2}>{t("同意2")}</span>
                </div> */}

                {
                  t("is_en") == '1' ?
                    <div style={{ textAlign: 'center', fontSize: '28px', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                      <input type="checkbox" style={{ width: '40px', height: '20px', position: 'absolute', top: '12px', left: '50px'}} checked={yiyue} onChange={yiyueHandle} id="gou" />
                      <div style={{marginLeft:'20px'}}>
                        <div style={{fontWeight:'300'}}>
                        <label htmlFor="gou"> {t("同意")}</label>
                        </div>
                        
                        <div style={{ textAlign: 'center' }}>
                          {/* <img src="/img/agree.png" alt="" style={{ width: '560px' }} onClick={yiyueHandle2}/> */}
                          <div style={{ textDecoration: 'underline' }} onClick={yiyueHandle2}>{t("同意2")}</div>
                          
                        </div>
                      </div>




                    </div>
                    :
                    <div style={{ textAlign: 'center', fontSize: '28px', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                      
                      <input type="checkbox" style={t("is_en") == '1' ? { width: '40px', height: '20px' } : { width: '40px', height: '20px', marginTop: '10px' }} checked={yiyue} onChange={yiyueHandle} id="gou" />
                      <div>
                      <label htmlFor="gou"> {t("同意")}</label>
                      <span style={{ textDecoration: 'underline' }} onClick={yiyueHandle2}>{t("同意2")}</span>
                      </div>
                      



                    </div>
                }


              </div>
              <div>
                <button className="overlap-9" onClick={tijiao}>
                  <div className="text-wrapper-21" > {t("f_meet_submit")}</div>
                </button>
              </div>

            </div>


          }

        </div>
      </div>
    </div>
  );
};
export default Information