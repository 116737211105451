import React from "react";

import "./style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeXuan } from "../../store/pageSlice";
import { useEffect } from "react";

const Introduce = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  useEffect(()=>{
    const div = document.documentElement.querySelector('.div');
    div.style.height=0
    setTimeout(() => {
      div.style.height='auto'
    }, 1)
    dispatch(changeXuan(1))
  })
  const zhItem = () => {
    return (
      <>
      

        {/* <div className="text-wrapper-4">尊敬的理想汽车合作伙伴：</div> */}
        {/* <div className="text-wrapper-5">
        理想汽车2024全球合作伙伴大会将于2024年10月17日在江苏常州举行。在此，我们向您发出诚挚邀请，共同见证理想汽车不断突破、超速成长的九年，心向理想，智领新征途!

          <br />
          <br />
          过去九年，理想汽车从未止步。感谢所有正在以及曾经为理想奋斗过的人，也感谢每一位相信理想、支持理想、选择理想的伙伴。每位合作伙伴的信任与支持，都是推动理想汽车破浪前行的磅礴力量。
          <br />
          <br />
          作为家庭用户市场的先行者，理想汽车自成立的第一天起便为家庭而生。从理想ONE到理想L系列，到为大家庭而生的划时代产品理想MEGA，理想汽车在所有合作伙伴的支持下，不断超越用户的需求，迈进“增程电动”与“高压纯电”并驾齐驱的新阶段。曾经以慢，厚积薄发;而今以快，弯道超车。理想汽车正在以惊人的速度为更多家庭用户带来惊喜，不断拓展幸福边界。这份荣誉属于每一个与理想汽车同行的合作伙伴，属于每一个与理想汽车同行的家庭。

          <br />
          <br />
          今天，我们有幸搭载科技发展的巨轮，乘风破浪，为智能电动车行业带来划时代的变革。未来，面对前路的考验，我们相信在所有合作伙伴的信赖与支持下，我们将实现跃新，携手共创新时代的理想!
          <br />
          <br />
          会议时间:2024年10月17日
          <br />
          会议地点:江苏省常州市中吴宾馆
          <br />
          会议地址:江苏省常州市钟楼区中吴大道1899号
          <br />
          温馨提示:如需了解更多会议信息，请联系您的采购经理。
          {/* <div style={{ textAlign: "right" }} className="ming">
            理想汽车
            <br />
            2024年10月12日
          </div> */}
      
        <img src="/img/yaoqing_zh-8-28.jpg" style={{width:'100%',marginTop:'950px'}}/>
        
      </>
    )
  }

  const enItem = () => {
    return (
      <>
        {/* <div className="text-wrapper-3">{t("j_title")}</div>
        <div className="text-wrapper-4">Dear Li Auto Partners, </div>
        <div className="text-wrapper-5">

        The 2024 Li Auto Global Partner Conference will beheld in Changzhou,Jiangsu,on October 17,2024.We cordially invite you to join us in witnessing therelentless breakthroughs and rapid growth of Li Autoover the past nine years. With our hearts set on ourideals and intelligence leading the new journey, welook forward to your presence.
          <br />
          <br />
          Over the past nine years, Li Auto has never stoppedadvancing. We are grateful to everyone who hasstrived for Li Auto and to every partner who hasbelieved in, supported, and chosen Li Auto. The trustand support of each partner are the driving forcesthat propel Li Auto to forge ahead.

          <br />
          <br />

          As a pioneer in the family user market, Li Auto hasbeen designed for families since its inception. Fromthe ldeal ONE to the ldeal L series, and to theera-defining product ldeal MEGA, which is born forlarger families, Li Auto has been supported by allpartners to continuously exceed user needs and entera new stage where "range-extended electric" and "high-voltage pure electric" drive systems go hand inhand. Once slow and accumulating strength, nowfast and overtaking on the curve. Li Auto is bringingsurprises to more family users at an astonishingspeed,continuously expanding the boundaries ofhappiness. This honor belongs to every partner whowalks with Li Auto and to every family who walks withLi Auto.
          <br />
          <br />
          Today, we are fortunate to embark on the giant shipof technological development, riding the wind andwaves, bringing epoch-making changes to the smartelectric vehicle industry. In the future, facing the testsahead, we believe that with the trust and support ofall partners, we will achieve leaps and bounds and
          create a new era of Li Auto together!
          <br />
          <br />
          Conference Date: October 17,2024
          <br />
          Conference Location: Zhongwu Hotel,Changzhou,Jiangsu
          <br />
          Conference Address: No.1899 Zhongwu Avenue,Zhonglou District,Changzhou,Jiangsu
          <br />
          Warm Reminder: For more conference information,please contact your purchasing manager

        </div> */}
        
        <img src="/img/9-5-yaoqing.png" style={{width:'100%',marginTop:'950px'}}/>
      </>
    )
  }
  return (
    <div className="introduce">
      <div className="div" >

        {/* <button onClick={() => { localStorage.removeItem('li_token')
        localStorage.removeItem('li_user_id') }} style={{ position: 'absolute' }}>登出</button> */}
        <img
          className="mask-group"
          alt="Mask group"
          src="/img/2024bg2.jpg"
        />
        <img src={t("is_en") == 1 ? "/img/logo-en.png" : "/img/login/logo.png"} alt="" className="logo" />
        
        {
          t("is_en") === '1' ? enItem() : zhItem()
        }

      </div>
    </div>
  );
};

export default Introduce
