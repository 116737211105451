import React from "react";
import "./style.css";
import {useState} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useSmsMutation, useLoginMutation} from '../../store/pageApi2';
import {useSearchParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

const Login = () => {
    const [count] = useSearchParams()
    const [sms] = useSmsMutation()
    const [login] = useLoginMutation()
    const [phone, setPhone] = useState('')
    const [know, setKnow] = useState(true)
    const [num, setNum] = useState(60)
    const [error, setError] = useState("")
    const [loginError, setLoginError] = useState("")
    const [lang, setLang] = useState('zh')
    const {t, i18n} = useTranslation();
    const [start, setStart] = useState(false)
    const nav = useNavigate()

    useEffect(() => {

        const body = document.querySelector('body')
        console.log(window.innerHeight)
        body.style.backgroundImage = 'url(/img/2024bg1.jpg)'
        const kuang = document.querySelector('.overlap-group-wrapper')
        console.log(kuang)
        const weizhi = (window.innerHeight - 1688) + 1045
        console.log(weizhi)
        console.log(kuang.style.marginTop)
        kuang.style.marginTop = `${weizhi}px`

    }, [])
    useEffect(() => {

        if (start) {
            countDown()
        } else {
            setNum(60)
        }
    }, [num])

    useEffect(() => {
        console.log(count.get('lan'))


        if (count.get('lan')) {
            i18n.changeLanguage(count.get('lan'));
            localStorage.setItem('li_lan', count.get('lan'))
            if (count.get('lan') == 'en') {
                document.title = ' Li Auto Global Partners Conference 2024'
            } else {
                document.title = '理想汽车2024全球合作伙伴大会'
            }
        } else {
            console.log('zh');
            i18n.changeLanguage('zh');
            localStorage.setItem('li_lan', 'zh')
            document.title = '理想汽车2024全球合作伙伴大会'
            // if (localStorage.getItem('li_lan')) {
            //   console.log('first')
            //   i18n.changeLanguage(localStorage.getItem('li_lan'));
            //   document.title = ' Li Auto Global Partners Conference 2024'
            //   if(localStorage.getItem('li_lan')=='en'){
            //     document.title = ' Li Auto Global Partners Conference 2024'
            //   }else{
            //     document.title = '理想汽车2024全球合作伙伴大会'
            //   }
            // }else{

            // }
        }
        if (localStorage.getItem('li_token') && localStorage.getItem('li_user_id')) {
            // props.funcLogin()
            nav('/home', {replace: true})
        }

    }, [])


    const countDown = () => {

        setError("")
        console.log('click')
        setTimeout(() => {
            setNum(state => {

                if (state === 1) {
                    setStart(false)
                    setNum(60)
                } else {
                    return state - 1
                }
            })

        }, 1000)


    }

    const countDown2 = () => {

        sms({
            phone
        }).then(res => {
            console.log(res)
            if (res.data.rs == 0) {
                setError(res.data.msg)
                setNum(60);
                setStart(false)

            } else if (res.data.rs == 3) {
                setKnow(false)
                setError(res.data.msg)
                setNum(60);
                setStart(false)
            } else {
                setStart(true)
                 countDown()
            }
        });


    }
    const knowItem = () => {

        return (
            <>
                <div className="rectangle"/>
                <div className="overlap-wrapper-2">
                    <div className="overlap-4">
                        <div className="text-wrapper-6">
                            {t("home_form_success")}

                        </div>
                        <img
                            className="image-2"
                            alt="Image"
                            src="/img/login/---.svg"
                        />
                        <button className="text-wrapper-7" onClick={() => {
                            setKnow(true)
                        }}>{t("home_form_success_btn")}
                        </button>
                    </div>
                </div>
            </>
        )
    }
    const phoneTest = function (value) {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
        if (
            !reg_tel.test(value)
        ) {
            return false
        } else {
            return true
        }
    }
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required(t("v_fill_name")),
        code: Yup.number()
            .required(t("v_fill_code")),
        phone: Yup.string()
            .test('phone', t("v_fill_phone"), phoneTest)
            .required(t("v_fill_phone_require")),
    });

    return (
        <div className="login">
            <div className="overlap-wrapper">

                <div className="overlap">
                    {/* <img
            className="element-l"
            alt="Element l"
            src="/img/2024bg1.jpg"
          /> */}
                    <img src={t("is_en") == 1 ? "/img/logo-en.png" : "/img/login/logo.png"} alt="" className="logo"/>
                    <div className="text-wrapper">

                        {t("home_title_1")}
                        <br/>
                        {t("home_title_2")}
                    </div>

                    <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                            <Formik
                                initialValues={{
                                    name: '',
                                    phone: '',
                                    code: ''
                                }}
                                validationSchema={SignupSchema}
                                onSubmit={values => {
                                    console.log(values)
                                    login({
                                        phone: values.phone,
                                        name: values.name,
                                        code: values.code,
                                    }).then(res => {
                                        console.log(res)

                                        if (res.data.rs === 1) {
                                            localStorage.setItem("li_token", res.data.token)
                                            localStorage.setItem("li_user_id", res.data.user_id)
                                            // props.funcLogin()
                                            nav('/home', {replace: true})

                                        } else if (res.data.rs == -1) {
                                            setKnow(false)
                                            setLoginError(res.data.msg)

                                        } else {
                                            setLoginError(res.data.msg)

                                        }

                                    });


                                }}
                            >
                                {({values, handleChange, handleBlur, errors, touched}) => (
                                    <Form>
                                        <div className="div">
                                            <div className="view">
                                                <div className="overlap-group-2">
                                                    <img
                                                        className="image"
                                                        alt="Image"
                                                        src="/img/login/--3.svg"
                                                    />
                                                    <div className="text-wrapper-2">{t("home_form_code")}</div>
                                                    <input type="number" name="code"
                                                           value={values.code}
                                                           onChange={handleChange}
                                                           onBlur={handleBlur}
                                                           placeholder={t("home_form_code_placeholder")}
                                                           style={{width: "200px"}}
                                                           className={errors.code && touched.code ?
                                                               "text-wrapper-3" : "text-wrapper-3"}

                                                    />
                                                    {
                                                        start ? <button className="text-wrapper-4"
                                                                        type="button">{num}</button> :
                                                            <button className="text-wrapper-4" type="button"
                                                                    onClick={countDown2}>{t("home_form_code_btn")}</button>
                                                    }
                                                    {errors.code && touched.code ? (
                                                        <div className='err'>{errors.code}</div>
                                                    ) : null}
                                                    {error ? (
                                                        <div className='err-code'>{error}</div>
                                                    ) : null}

                                                    <img
                                                        className="img"
                                                        alt="Image"
                                                        src="/img/login/----4.svg"
                                                    />
                                                </div>
                                            </div>
                                            <div className="group-2">
                                                <div className="overlap-2">
                                                    <div className="div-wrapper">
                                                        <div className="overlap-group-3">
                                                            <div className='text-wrapper-2'>{t("home_form_phone")}</div>
                                                            <input type="number" name="phone"
                                                                   value={values.phone}
                                                                   onChange={(e) => {
                                                                       handleChange(e);
                                                                       setPhone(e.target.value)
                                                                   }}
                                                                   onBlur={handleBlur}
                                                                   placeholder={t("home_form_phone_placeholder")}
                                                                   className={errors.phone && touched.phone ?
                                                                       "text-wrapper-3" : "text-wrapper-3"}
                                                            />
                                                            {errors.phone && touched.phone ? (
                                                                <div className='err'>{errors.phone}</div>
                                                            ) : null}

                                                        </div>
                                                    </div>
                                                    <img
                                                        className="img"
                                                        alt="Image"
                                                        src="/img/login/----4.svg"
                                                    />
                                                </div>
                                                <div className="div-wrapper">
                                                    <div className="overlap-group-2">
                                                        <img
                                                            className="image"
                                                            alt="Image"
                                                            src="/img/login/--1.svg"
                                                        />
                                                        <img
                                                            className="img"
                                                            alt="Image"
                                                            src="/img/login/----4.svg"
                                                        />
                                                        <div className="text-wrapper-2">{t("home_form_name")}</div>
                                                        <input type="" name="name"
                                                               value={values.name}
                                                               onChange={handleChange}
                                                               onBlur={handleBlur}
                                                               placeholder={t("home_form_name_placeholder")}
                                                               className={errors.name && touched.name ?
                                                                   "text-wrapper-3" : "text-wrapper-3"}
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <div className='err'>{errors.name}</div>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {loginError ? (
                                            <div className='err-login'>{loginError}</div>
                                        ) : null}
                                        <button className="overlap-3" type="submit">
                                            <div className="text-wrapper-5">{t("home_form_submit")}</div>
                                        </button>

                                        <button type='submit'
                                                className="btn btn-default form-control form-control-login btn-submit "
                                                style={{
                                                    margin: '20px 0 0px 0',
                                                    color: '#fff',
                                                    textTransform: "uppercase"
                                                }}
                                        >{t("home_form_submit")}</button>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                    {
                        know ? null : knowItem()
                    }
                </div>
            </div>
        </div>
    );
};

export default Login
