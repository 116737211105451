import React, { useEffect } from 'react';
import Routes from '../../router';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Login from '../login';
import { NavLink } from 'react-router-dom';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { changeXuan } from '../../store/pageSlice';
import {useInfoMutation} from "../../store/pageApi2";
const Home = () => {
    const [isLogin, setIsLogin] = useState(false)
    // const [xuan, setxuan] = useState(1)
    const nav=useNavigate()

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {pageSlice} = useSelector(state => state);
    const funcLogin = () => {
        setIsLogin(true)
    }
    const [info, { data: infoData }] = useInfoMutation()

    useEffect(()=>{
        const body=document.querySelector('body')
    
    body.style.backgroundImage='none'
    console.log(localStorage.getItem('li_lan'));
    // i18n.changeLanguage(localStorage.getItem('li_lan'))
    if(localStorage.getItem('li_lan')){
        i18n.changeLanguage(localStorage.getItem('li_lan'));
    }
    
    },[])

    useEffect(() => {

        info({
            token: localStorage.getItem('li_token')
        }).then(res => {
            console.log(res)

            if (res.data.rs === 1) {

            } else if (res.data.rs === 0) {
                localStorage.removeItem('li_token')
                localStorage.removeItem('li_user_id')
                nav('/login', { replace: true })
            } else {

            }

            if (res.data.internal == 1) {

            }

        });
    }, [])



    const loginItem = () => {
        return (
            <>
                <Outlet />
                
                <div className='home'>

                    <div className="overlap-wrapper">
                        <div className="overlap">
                            <NavLink to='/home/recommend' replace onClick={() => { dispatch(changeXuan(4))
                                 }}>
                                <div className="overlap-2">
                                    <img
                                        className="img-4"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 4 ? "/img/t1.svg" : "/img/t0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" >{t("nav_4")}</div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/application' replace onClick={() => { dispatch(changeXuan(3)) }}>
                                <div className="overlap-3">
                                    <img
                                        className="img-3"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 3 ? "/img/b1.svg" : "/img/b0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'-15px'}:{}}>{t("nav_3")}</div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/introduce' replace onClick={() => {dispatch(changeXuan(1)) }}>
                                <div className="overlap-4">
                                    <img
                                        className="img-1"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 1 ? "/img/j1.svg" : "/img/j0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'-3px'}:{}}>{t("nav_1")}</div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/information' replace onClick={() => { dispatch(changeXuan(2)) }}>
                                <div className="overlap-5">
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'-11px'}:{}}>{t("nav_2")}</div>
                                    <div className="view-2">
                                        <img
                                            className="img-2"
                                            alt="Element"
                                            src={
                                                pageSlice.xuan === 2 ? "/img/x1.svg" : "/img/x0.svg"
                                            }
                                        />
                                    </div>
                                </div>
                            </NavLink>
                
                            <NavLink to='/home/moment' replace onClick={() => { dispatch(changeXuan(5)) }}>
                                <div className="overlap-7">
                                    <div className="view-2">
                                        <img
                                            className="img-5"
                                            alt="Element"
                                            src={
                                                pageSlice.xuan === 5 ? "/img/s1.svg" : "/img/s0.svg"
                                            }
                                        />
                                    </div>
                                    <div className="text-wrapper-2" >{t("nav_5")}</div>
                                </div>
                                </NavLink>
                        </div>
                    </div>

                </div>
            </>

        )
    }


    return (
        <div>
            
            {
                loginItem()
            }

        </div>
    );
}

export default Home;
