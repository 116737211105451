import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import "./style.css";
import { useEffect } from 'react';

const Qianming = (props) => {
    const sigCanvas=React.createRef()
    useEffect(()=>{
      const box=document.documentElement.querySelector('.box')
      console.log(window.innerHeight)
      box.style.height=window.innerHeight+'px'
      
    },[])
    return (
        <div className='box'>
            <div className='title'>
                在线签名
            </div>
            <div className='sign'>
                <SignatureCanvas penColor='black'
                    canvasProps={{ width: 780, height: 780, className: 'sigCanvas' }} ref={sigCanvas}/>
            </div>
            <div className='box-btn'>
            <button onClick={()=>{
              sigCanvas.current.clear()
            }}>重新签名</button>
            <button onClick={()=>{
                console.log(sigCanvas.current.toDataURL());
                props.close(sigCanvas.current.toDataURL())
                
            }}>确认</button>
            {/* <img src={`data:image/jpeg;base64,${sigCanvas.current.toDataURL()}`}alt="" /> */}
            </div>
            

        </div>
    )

}
export default Qianming



